<template>
  <div>
    <div v-if="!hideOverlay" id="overlay">
      <input v-model="password" type="password" id="overlay-input" placeholder="Enter password" />
      <button id="overlay-button" @click="unlockScreen(password)">Submit</button>
    </div>
    <v-form @submit.prevent="updateCompetition">
      <v-select v-model="competition" :items="getCompetitions" item-text="name" return-object
        label="Competition"></v-select>
      <div class="text-h6">Current Matchday {{ competition.currentMatchday }}</div>
      <div v-for="(matchday, index) in competition.matchdays" :key="matchday.id" class="my-1">
        <div class="mt-1" v-if="!matchday.id">
          <div class="text-h6">Matchday {{ matchday.id }}</div>
          <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="matchday.startDateString" label="Matchday Start Date" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="matchday.startDateString" @input="menu3= false"></v-date-picker>
          </v-menu>
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
            :return-value.sync="matchday.startTimeString" transition="scale-transition" offset-y max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="matchday.startTimeString" label="Matchday start time"
                prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-time-picker v-if="menu" v-model="matchday.startTime" full-width
              @click:minute="$refs.menu.save(matchday.startTime)"></v-time-picker>
          </v-menu>
          <v-menu v-model="menu4" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="matchday.endDateString" label="Matchday End Date" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="matchday.endDateString" @input="menu4 = false"></v-date-picker>
          </v-menu>
          <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
            :return-value.sync="matchday.endTimeString" transition="scale-transition" offset-y max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="matchday.endTimeString" label="Matchday end time"
                prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-time-picker v-if="menu2" v-model="matchday.endTime" full-width
              @change="save(matchday, index)"></v-time-picker>
          </v-menu>
        </div>
        <div v-else>PRINT MATCHDAY INFO HERE</div>
      </div>
      <v-btn v-if="competition.id" @click="addMatchday()">Add Matchday</v-btn>
      <v-switch v-model="competition.predictionsOpen" label="Predictions Open"></v-switch>
      <v-btn type="submit" color="primary">Update Competition</v-btn>
    </v-form>
  </div>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      competition: {},
      sports: ['Football', 'Hurling', 'Rugby', 'Soccer'],
      menu: [false],
      menu2: [false],
      menu3: [false],
      menu4: [false],
      password: '',
      hideOverlay: false
    };
  },
  mounted() {
    this.fetchCompetitions();
  },
  methods: {
    ...mapActions(["fetchCompetitions"]),
    updateCompetition() {
      this.$store.dispatch('updateCompetition', this.competition);
    },
    addMatchday() {
      this.competition.matchdays.push({ matchday: this.competition.matchdays.length + 1 })
    },
    save(item, index){
      console.log(item)
    },
    unlockScreen(password) {
      if (password == 'briandillons') {
        this.hideOverlay = true;
      } else {
        this.password = null;
      }
    }
  },
  computed: {
    ...mapGetters(["getCompetitionPredictionsOpen", "getCompetitions"])
  }
};
</script>

<style scoped>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  z-index: 9999;
  /* High z-index to stay on top */
}

#overlay input {
  padding: 10px;
  font-size: 16px;
  width: 300px;
  color: white;
}

#overlay button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>

  